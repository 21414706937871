/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-11-11",
    versionChannel: "nightly",
    buildDate: "2023-11-11T00:21:36.380Z",
    commitHash: "172b76d01f42ba71bedba7be5f36ddce1c5c5346",
};
